import React from 'react'
import { Link as RouterLink } from 'gatsby'

import { Box, Link } from 'components'

const ButtonBar = ({
  buttons = [],
  selectedId = null,
  breakOnMobile,
  minWidth,
  fullWidth,
}) => {
  return (
    <Box
      sx={{
        position: 'relative',

        pr: breakOnMobile && fullWidth ? 0 : 4,

        '&:before': {
          position: 'absolute',
          mr: 0,
          top: 0,
          bottom: 0,
          right: 0,
          width: 40,
          height: '100%',
          content: breakOnMobile && fullWidth ? 'none' : '""',
          zIndex: 20,
          pointerEvents: 'none',
          backgroundImage:
            'linear-gradient(90deg, rgba(255, 251, 252, 0) 0%, #F9F6F1 100%)',
        },
      }}
    >
      <Box
        sx={{
          overflowX: 'auto',
          mx: -3,
        }}
      >
        <Box
          sx={{
            display: 'inline-block',
            minWidth: '100%',
            verticalAlign: 'middle',
            px: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: breakOnMobile ? ['wrap', 'nowrap'] : 'nowrap',
              minWidth: minWidth,
            }}
          >
            {buttons.map(({ id, to, href, onClick, children }, index) => {
              const handleClick = () => onClick(id)
              const isSelected = selectedId === id
              const componentProps = onClick
                ? {
                    as: 'button',
                    onClick: handleClick,
                  }
                : {
                    as: to ? RouterLink : 'a',
                    to,
                    href,
                  }
              return (
                <Link
                  key={id}
                  {...componentProps}
                  sx={{
                    display: 'inline-block',
                    fontSize: 0,
                    px: 4,
                    py: 2,
                    lineHeight: 1,
                    bg: 'transparent',
                    ...(index === 0 && {
                      borderRadius: breakOnMobile
                        ? ['3px 3px 0 0', '3px 0 0 3px']
                        : '3px 0 0 3px',
                    }),
                    ...(index === buttons.length - 1 && {
                      borderRadius: breakOnMobile
                        ? ['0 0 3px 3px', '0 3px 3px 0']
                        : '0 3px 3px 0',
                    }),
                    border: '1px solid',
                    borderColor: isSelected ? 'darkNavy100' : 'darkNavy20',
                    color: isSelected ? 'darkNavy100' : 'darkNavy60',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                    ml: breakOnMobile ? [0, '-1px'] : '-1px',
                    mt: breakOnMobile ? [index === 0 ? 0 : '-1px', 0] : 0,
                    textAlign: 'center',
                    zIndex: isSelected ? 5 : 1,
                    cursor: 'pointer',

                    ...(fullWidth && {
                      flexBasis: breakOnMobile ? ['100%', '50%'] : '50%',
                    }),

                    '&:hover': {
                      borderColor: 'darkNavy100',
                      zIndex: 10,
                    },
                  }}
                >
                  {children}
                </Link>
              )
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ButtonBar
